import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import {config_Data_array} from './big_data';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pdf_modal: {show: false, name: 'WOW'},
        alert_snackbar: {
            content: '',
            color: '',
            type: ''
        },
        pdf_modal_show: false,
        overlay_loading: false,
        refreshMarker: false,
        data_geo: {
            'type': 'FeatureCollection',
            'features': []
        },
        data_array: [],
        annee_all: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
        selected_item: null,
        annee_filter: null,
        config_selected: [],
        filter: [],
        marker_location: null,
        config_all: {
            evo_pop: {
                page: 'evo_pop_iris',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Population'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 14,
                type_geo: 'choropleth',
                sources: ['INSEE']
            },
            evo_pop_com: {
                page: 'evo_pop_com',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Population'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 11,
                type_geo: 'choropleth',
                sources: ['INSEE'],
                second: {
                    page: 'evo_pop_iris',
                    zoom: 15,
                    type_geo: 'choropleth',
                    sources: ['INSEE'],
                    active: false
                }
            },
            struct_pop_com: {
                page: 'struct_pop_com',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Population'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 11,
                type_geo: 'choropleth',
                show_filter: false,
                sources: ['INSEE'],
                second: {
                    page: 'struct_pop_iris',
                    zoom: 14,
                    type_geo: 'choropleth',
                    active: false,
                    sources: ['INSEE']
                }
            },
            cap_achat: {
                page: 'cap_achat',
                sheet: true,
                label: true,
                value: {
                    key: 'key',
                    metric: 'Moyenne achat sur 25 ans'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 15,
                type_geo: 'choropleth',
                sources: ['INSEE']
            },
            preco_com: {
                page: 'preco_com',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Prix bas'
                },
                colorScale: ['DCDCDC', 'e7d090', 'e9ae7b', 'de7062'],
                zoom: 11,
                type_geo: 'choropleth',
                sources: ['Inovefa Expertise'],
                second: {
                    page: 'preco_iris',
                    zoom: 15,
                    type_geo: 'choropleth',
                    active: false,
                    label: true,
                    sources: ['Inovefa Expertise']
                }
            },
            parc_immo_com: {
                page: 'parc_immo_com',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Nombre de logement'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 11,
                type_geo: 'choropleth',
                sources: ['INSEE'],
                second: {
                    page: 'parc_immo_iris',
                    zoom: 15,
                    type_geo: 'choropleth',
                    active: false,
                    sources: ['INSEE']
                }
            },
            parc_social: {
                page: 'parc_social',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Nbr. logements dans le parc'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 10,
                type_geo: 'choropleth',
                sources: [
                    'INSEE',
                    'Ministère de la Transition Écologique - Statistiques sur le développement durable'
                ]
            },
            marche_ancien: {
                page: 'marche_ancien',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Evo. PMC sur 9 ans'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 10,
                type_geo: 'choropleth',
                sources: ['Base DVF']
            },
            marche_locatif: {
                page: 'marche_locatif',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Taux locatif privé'
                },
                colorScale: ['e7d090', 'e9ae7b', 'de7062'],
                zoom: 13,
                type_geo: 'choropleth',
                sources: ['INSEE', 'Inovefa Expertise']
            },

            gentrification: {
                page: 'gentrification',
                sheet: true,
                value: {
                    key: 'key',
                    metric: 'Augmentation de la part des CSP+'
                },
                colorScale: ['06850b', '4caf50', 'eeeeee', 'a42727', 'a30000'],
                zoom: 13,
                type_geo: 'choropleth',
                sources: ['INSEE']
            },
            transports: {
                page: 'transports',
                sheet: false,
                type_geo: 'point',
                zoom: 14,
                show_filter: true
            },
            etablissements_scolaires: {
                page: 'etablissements_scolaires',
                sheet: false,
                type_geo: 'point',
                zoom: 14
            },
            points_vigilance: {
                page: 'points_vigilance',
                sheet: false,
                type_geo: 'point',
                zoom: 14
            },
            permis_construire: {
                page: 'permis_construire',
                sheet: true,
                type_geo: 'point',
                zoom: 14,
                sources: ['Base des permis de construire SITADEL'],
                show_filter: true
            },
            points_interets: {
                page: 'points_interets',
                sheet: false,
                type_geo: 'multi_geo',
                zoom: 14,
                show_filter: true
            },
            programmes_neufs: {
                page: 'programmes_neufs',
                sheet: true,
                type_geo: 'point',
                zoom: 10,
                sources: ['Inovefa Expertise']
            },
            zone_tva: {
                page: 'zone_tva',
                sheet: true,
                type_geo: 'geojson_zone',
                zoom: 10,
                show_filter: true,
                sources: ['SIG - Politique de la Ville']
            },
            operation_amen: {
                page: 'operation_amen',
                sheet: true,
                type_geo: 'geojson_zone',
                zoom: 12,
                show_filter: true,
                sources: [
                    'Producteur : Atelier Parisien d’Urbanisme',
                    'Traitement : Inovefa Expertise'
                ]
            },
            zone_peb: {
                page: 'zone_peb',
                sheet: true,
                type_geo: 'geojson_zone',
                zoom: 10
            },
            mensualite: {
                page: 'mensualite',
                sheet: true,
                open_instant: true
            },
            location: {
                page: 'location',
                sheet: true,
                open_instant: true
            },
            pinel: {
                page: 'pinel',
                sheet: true,
                label: true,
                type_geo: 'choropleth',
                zoom: 12,
                value: {
                    key: 'key',
                    metric: 'Loyer'
                },
                colorScale: ['fff2cc', 'e7d090', 'e9ae7b', 'de7062'],
                sources: ['Ministère de la cohésion des territoires']
            },
            couverture_fibre: {
                page: 'couverture_fibre',
                sheet: true,
                label: true,
                type_geo: 'choropleth',
                zoom: 12,
                value: {
                    key: 'key',
                    metric: 'Couverture'
                },
                colorScale: ['fff2cc', 'e7d090', 'e9ae7b', 'de7062'],
                sources: ['Ministère de la cohésion des territoires']
            },
            PLU: {
                page: 'PLU',
                sheet: true,
                label: true,
                type_geo: 'choropleth',
                zoom: 12,
                value: {
                    key: 'key',
                    metric: 'Loyer'
                },
                colorScale: ['fff2cc', 'e7d090', 'e9ae7b', 'de7062'],
                sources: ['Ministère de la cohésion des territoires']
            },
            menage_solvable: {
                page: 'menage_solvable',
                sheet: true,
                type_geo: 'choropleth',
                zoom: 12,
                value: {
                    key: 'key',
                    metric: 'Mediane'
                },
                colorScale: ['DCDCDC', 'e7d090', 'e9ae7b', 'de7062'],
                sources: ['INSEE']
            },
            taxes_foncieres: {
                page: 'taxes_foncieres',
                sheet: true,
                type_geo: 'choropleth',
                zoom: 12,
                value: {
                    key: 'key',
                    metric: 'Taxe mc'
                },
                colorScale: ['DCDCDC', 'e7d090', 'e9ae7b', 'de7062'],
                sources: ['DGfip', 'Data gouv']
            },
            marche_ancien_geoloc: {
                page: 'marche_ancien_geoloc',
                sheet: true,
                type_geo: 'point',
                zoom: 17,
                precise_cluster: true,
                sources: ['DVF - Base Demande de valeurs foncières'],
                show_filter: true,
            },
            marche_neuf_geoloc: {
                page: 'marche_neuf_geoloc',
                sheet: true,
                type_geo: 'point',
                zoom: 17,
                precise_cluster: true,
                sources: ['DVF - Base Demande de valeurs foncières'],
                show_filter: true
            },
            logements_sociaux: {
                page: 'logements_sociaux',
                sheet: true,
                type_geo: 'point',
                zoom: 15,
                sources: ['INSEE']
            },
            commerce: {
                page: 'commerce',
                sheet: false,
                type_geo: 'point',
                zoom: 15,
                show_filter: true
            }
        }
    },
    getters: {
        get_alert_snackbar: state =>
        {
            return state.alert_snackbar;
        },
        get_marker_location: state =>
        {
            return state.marker_location;
        },
        pdf_modal_show: state =>
        {
            return state.pdf_modal_show;
        },
        pdf_modal: state =>
        {
            return state.pdf_modal;
        },
        getOverlay_loading(state)
        {
            return state.overlay_loading;
        },
        getConfig_selected(state)
        {
            return state.config_selected;
        },
        getSelected_item(state)
        {
            return state.selected_item;
        },
        getAnnee_all(state)
        {
            return state.annee_all;
        },
        getAnnee_filter(state)
        {
            return state.annee_filter;
        },
        getData_geo(state)
        {
            return state.data_geo;
        },
        getFilter(state)
        {
            return state.filter;
        }
    },
    mutations: {
        set_alert_snackbar(state, value)
        {
            state.alert_snackbar = value;
        },
        set_pdf_modal_show(state, value)
        {
            state.pdf_modal_show = value;
        },
        set_pdf_modal(state, value)
        {
            state.pdf_modal = value;
        },
        mutateLocation(state, payload)
        {
            state.marker_location = payload;
        },
        mutateActivateSecond(state)
        {
            state.data_geo.features = [];
            state.data_array = [];
            state.selected_item = null;
            state.config_selected.second.active = !state.config_selected.second
                .active;
        },
        mutateRefreshMarker(state, payload)
        {
            state.refreshMarker = !state.refreshMarker;
        },
        mutateOverlay_loading(state)
        {
            state.overlay_loading = !state.overlay_loading;
        },
        mutateData_geo(state, payload)
        {
            let tabFeatures = [];
            payload.data.forEach(element =>
            {
                var filteredNames = Object.keys(element).filter(name =>
                    /selected/.test(name)
                );
                if (
                    state.data_geo.features.find(
                        element_2 => element_2.properties.id === element.id
                    ) === undefined &&
                    element[filteredNames]?.length !== 0
                )
                {
                    let properties_temp = {};
                    let elem =
                        filteredNames.length !== 0 ? element[filteredNames][0] : element;
                    for (const key in elem)
                    {
                        properties_temp['id'] = element.id;
                        properties_temp[key] = elem[key];
                    }
                    tabFeatures.push({
                        type: 'Feature',
                        properties: properties_temp,
                        geometry: element.geometry
                    });
                }
            });
            state.data_geo.features.push(...tabFeatures);
        },
        mutateData_array(state, payload)
        {
            let tabData = [];
            payload.data.forEach(element =>
            {
                var filteredNames = Object.keys(element).filter(name =>
                    /selected/.test(name)
                );
                if (
                    state.data_array.find(element_2 => element_2.id === element.id) ===
                    undefined &&
                    element[filteredNames]?.length !== 0
                )
                {
                    tabData.push(
                        config_Data_array(
                            element,
                            filteredNames,
                            state.config_selected.second
                                ? state.config_selected.second.type_geo
                                : state.config_all[state.config_selected.page].type_geo
                        )
                    );
                }
            });
            state.data_array.push(...tabData);
        },
        mutateSelected_item(state, payload)
        {
            state.selected_item = payload;
        },
        resetData(state)
        {
            state.data_geo.features = [];
            state.data_array = [];
        },
        switchReset(state)
        {
            state.filter = [];
            state.data_geo.features = [];
            state.data_array = [];
            state.selected_item = null;
        },
        mutateConfig_selected(state, payload)
        {
            this.commit('resetData');
            state.config_selected = state.config_all[payload];
        },
        mutateAnnee_all(state, payload)
        {
            let temp_array = [];
            var filteredNames = Object.keys(payload).filter(name =>
                /selected/.test(name)
            );
            payload[filteredNames].forEach(element =>
            {
                temp_array.push(element.annee);
            });
            state.annee_all = [...temp_array];
        },
        mutateChangeData_array(state, payload)
        {
            state.config_selected.value.key = payload.value;
        },
        mutateFilter(state, payload)
        {
            state.filter = payload;
        }
    },
    actions: {
        actionData_geo(context, payload)
        {
            context.commit('mutateOverlay_loading');
            axios
                .post(`api/inodb/geo`, payload, {
                    withCredentials: true
                })
                .then(res =>
                {
                    if (res.data.length !== 0)
                    {
                        context.commit('mutateData_geo', res);
                        context.commit('mutateData_array', res);
                    }
                    context.commit('mutateOverlay_loading');
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services',
                        color: 'red',
                        type: 'error'
                    });
                });
        },
        actionGeo_zone(context, payload)
        {
            context.commit('mutateOverlay_loading');
            axios
                .post(`api/inodb/polygon`, payload, {
                    withCredentials: true
                })
                .then(res =>
                {
                    if (res.data.length !== 0)
                    {
                        context.commit('mutateData_geo', res);
                        context.state.config_selected.type_geo === 'choropleth'
                            ? context.commit('mutateData_array', res)
                            : null;

                    }
                    context.commit('mutateOverlay_loading');
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services.',
                        color: 'red',
                        type: 'error'
                    });
                });
        },
        actionData_marker(context, payload)
        {
            context.commit('mutateOverlay_loading');
            axios
                .post(`api/inodb/marker`, payload, {
                    withCredentials: true
                })
                .then(res =>
                {
                    context.commit('mutateData_geo', res);
                    context.commit('mutateRefreshMarker');
                    context.commit('mutateOverlay_loading');
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services',
                        color: 'red',
                        type: 'error'
                    });
                });
        },
        actionSelected_item(context, payload)
        {
            axios
                .post(`api/inodb/find/selected`, payload, {
                    withCredentials: true
                })
                .then(res =>
                {
                    context.commit('mutateSelected_item', res.data);
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services',
                        color: 'red',
                        type: 'error'
                    });
                });
        },
        actionPdf(context, payload)
        {
            axios
                .post(
                    `api/inodb/rapport`,
                    context.state.marker_location,
                    {
                        withCredentials: true
                    }
                )
                .then(res =>
                {
                    context.commit('set_pdf_modal', {
                        name: res.data.pdf,
                        show: true
                    });
                    context.commit('set_pdf_modal_show', true);
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services',
                        color: 'red',
                        type: 'error'
                    });
                });
        },
        actionData_marker_api(context, payload)
        {
            context.commit('mutateOverlay_loading');
            let payload_2 = {
                apikey: 'dev_edii_2021',
                method: 'general',
                longitude: payload.lng,
                latitude: payload.lat,
                radius: 1000
            };

            axios
                .post(
                    `https://etudes.inovefa-expertise.com/api_edii/api.php`,
                    payload_2
                )
                .then(res =>
                {
                    context.commit('mutateData_geo', res);
                    context.commit('mutateRefreshMarker');
                    context.commit('mutateOverlay_loading');
                })
                .catch(() =>
                {
                    context.commit('set_alert_snackbar', {
                        content:
                            'Oops ! Il y a eu une erreur, si cela continue veuillez prendre contact avec nos services',
                        color: 'red',
                        type: 'error'
                    });
                });
        }
    }
});
